import React from 'react';
import cs from 'classnames';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

import s from './infoPopUp.scss';
import { getEsi } from '../../utils';
import { StoreTemplate } from '../../store/reducers/template';

interface EditButtonProps extends WithTranslation {
  template: StoreTemplate;
  editorClick: (esi: string | undefined, origin: string) => any;
  target?: string;
}

function EditButton({ t, template, editorClick, target }: EditButtonProps) {
  return (
    <a
      data-hooks="card-editor-url"
      className={cs(s.editor, s.button)}
      {...(target && { target })}
      onClick={() => editorClick(getEsi(template.editorUrl), 'viewer_more')}
      href={template.editorUrl}
    >
      {t('template.viewer.info.edit.button')}
    </a>
  );
}

interface DesktopOnlyNoticeProps extends WithTranslation {}

function DesktopOnlyNotice({ t }: DesktopOnlyNoticeProps) {
  return (
    <div className={s.desktopOnlyNotice} data-hooks="desktop-only-notice">
      {t('template.viewer.info.desktop.only.notice')}
    </div>
  );
}

export interface InfoPopUpCmpProps extends WithTranslation {
  template: StoreTemplate;
  isInfoShown: boolean;
  toggleInfoMode: (show: boolean) => any;
  editorClick: (esi: string | undefined, origin: string) => any;
  isMobile?: boolean;
}

function InfoPopUpCmp({ t, i18n, template, isInfoShown, toggleInfoMode, editorClick, isMobile }: InfoPopUpCmpProps) {
  return (
    <div data-hooks="info-pop-up" className={cs(s.popupWrapper, { [s.show]: isInfoShown })}>
      <div className={s.card}>
        {!isMobile && (
          <button data-hooks="card-close" className={s.closeButton} onClick={() => toggleInfoMode(!isInfoShown)}>
            <span className={s.hide}>{t('a11y.close.popup.button')}</span>
          </button>
        )}
        <div className={s.content}>
          <h1 data-hooks="card-title" className={s.title}>
            {t('template.viewer.info.title', { title: template.title })}
          </h1>
          <div className={s.feature}>
            <h3 data-hooks="card-good-for-title" className={s.subtitle}>
              {t('template.viewer.info.goodFor')}
            </h3>
            <p data-hooks="card-good-for" className={s.runningText}>
              {template.goodFor}
            </p>
          </div>
          <div className={s.feature}>
            <h3 className={s.subtitle}>{t('template.viewer.info.description')}</h3>
            <p data-hooks="card-description" className={s.runningText}>
              {template.description}
            </p>
          </div>
        </div>
        <div className={s.footer}>
          {isMobile ? (
            <DesktopOnlyNotice t={t} i18n={i18n} tReady />
          ) : (
            <EditButton i18n={i18n} tReady target="_blank" t={t} template={template} editorClick={editorClick} />
          )}
        </div>
      </div>
    </div>
  );
}

export const InfoPopUp = withTranslation()(InfoPopUpCmp);
