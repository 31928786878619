import { AnyAction } from 'redux';
import { VIEW_MODE } from '../../utils/helpers';
import { TOGGLE_VIEW_MODE } from '../actions';

export function viewMode(state = VIEW_MODE.DESKTOP, action: AnyAction) {
  switch (action.type) {
    case TOGGLE_VIEW_MODE:
      return action.viewMode;
    default:
      return state;
  }
}
