import React from 'react';
import s from './MobileEditorXToolbar.scss';
import * as BIManager from '../../../utils/bi';
import { EditorXLogo } from '../EditorXLogo/EditorXLogo';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

interface Props extends WithTranslation {
  template: any;
  isEditButtonHidden: boolean;
}

const MobileEditorXToolbarCmp = ({ t, template, isEditButtonHidden }: Props) => {
  const getStartedText = t('template.viewer.getStarted');
  const reportGetStartedClick = () => BIManager.reportActionButtonClick('get_started', getStartedText);

  return (
    <div className={s.mobileEditorXToolbar} data-hooks="new-mobile-editorx-toolbar">
      <EditorXLogo isLarge={false} />
      {!isEditButtonHidden && (
        <a
          data-hooks="get-started-button"
          className={s.button}
          href={template.editorUrl}
          onClick={reportGetStartedClick}
        >
          {getStartedText}
        </a>
      )}
    </div>
  );
};

export const MobileEditorXToolbar = withTranslation()(MobileEditorXToolbarCmp);
