import React, { Component } from 'react';
import s from './infoButton.scss';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';

export interface InfoButtonProps extends WithTranslation {
  toggleInfoMode: (infoMode: boolean) => any;
  isInfoShown: boolean;
  hasBackButton?: boolean;
}

class InfoButtonCmp extends Component<InfoButtonProps> {
  render() {
    const { t, toggleInfoMode, isInfoShown, hasBackButton } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        data-hooks="info-view"
        className={`${s.infoIcon} ${isInfoShown && !hasBackButton ? s.selected : ''}`}
        tabIndex={0}
        onClick={() => toggleInfoMode(!isInfoShown)}
        onKeyPress={(e: any) => e.charCode === 13 && toggleInfoMode(!isInfoShown)}
        role="dialog"
        href="#"
      >
        {isInfoShown && hasBackButton ? t('template.viewer.back') : t('template.viewer.read.more')}
      </a>
    );
  }
}

export const InfoButton = withTranslation()(InfoButtonCmp);
