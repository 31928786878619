import { combineReducers, Reducer } from 'redux';

export const template: Reducer<StoreTemplate> = combineReducers({
  url: (state = '') => state,
  price: (state = '') => state,
  description: (state = '') => state,
  title: (state = '') => state,
  docUrl: (state = '') => state,
  editorUrl: (state = '') => state,
  goodFor: (state = '') => state,
  siteId: (state = '') => state,
  image: (state = '') => state,
  id: (state = '') => state,
  lng: (state = '') => state,
  metaSiteId: (state = '') => state,
  editorSessionId: (state = '') => state,
  isResponsive: (state = false) => state,
});

export interface StoreTemplate {
  url: string;
  price: string;
  description: string;
  title: string;
  docUrl: string;
  editorUrl: string;
  goodFor: string;
  siteId: string;
  image: string;
  id: string;
  lng: string;
  metaSiteId: string;
  editorSessionId: string;
  isResponsive: boolean;
  isAdiTemplate?: boolean;
}
