import React from 'react';
import s from './WixLogo.scss';

interface Props {
  onClick?: (...args: any[]) => any;
}

export function WixLogo(props: Props) {
  return (
    <a data-hooks="logo" href="https://www.wix.com" onClick={props.onClick} className={`${s.logoIcon} ${s.logo}`}>
      <span className={s.hide}>wix.com</span>
    </a>
  );
}
