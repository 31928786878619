import { VIEW_MODE } from '../../utils/helpers';

export const TOGGLE_VIEW_MODE = 'TOGGLE_VIEW_MODE';
export function toggleViewMode(viewMode: VIEW_MODE) {
  return {
    type: TOGGLE_VIEW_MODE,
    viewMode,
  };
}

export const TOGGLE_INFO_MODE = 'TOGGLE_INFO_MODE';
export function toggleInfoMode(show: boolean) {
  return {
    type: TOGGLE_INFO_MODE,
    show,
  };
}
