import React from 'react';
import cs from 'classnames';
import { VIEW_MODE } from '../../utils/helpers';
import s from './templateDemo.scss';
import { DesktopView } from '../partials/DesktopView/DesktopView';
import { MobileView } from '../partials/MobileView/MobileView';

interface Props {
  url: string;
  title: string;
  viewMode: VIEW_MODE;
  isInfoShown: boolean;
}

export function TemplateDemo({ url, title, viewMode, isInfoShown }: Props) {
  return (
    <div data-hooks="template-demo" className={cs(s.demoDisplay, { [s.disabled]: isInfoShown })}>
      {viewMode === VIEW_MODE.DESKTOP ? (
        <DesktopView url={url} title={title} />
      ) : (
        <MobileView url={url} title={title} />
      )}
    </div>
  );
}
