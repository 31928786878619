/* eslint camelcase: "off" */

import URL from 'url';
import biLogger from '@wix/web-bi-logger';

export const bi = biLogger.factory({ endpoint: 'spettatore_bi' }).updateDefaults({ src: 2 }).logger();
export const biGeneric = biLogger.factory({ endpoint: 'generic' }).updateDefaults({ src: 2 }).logger();
export const biEditorX = biLogger.factory({ endpoint: 'editor-x' }).updateDefaults({ src: 110 }).logger();

export interface BiOptions {
  siteId?: string;
  originUrl?: string;
  galleryDocIndex?: string;
  bookName?: string;
  isAdi?: boolean;
  referer?: string;
}
let biOptions: BiOptions;
export const setBIOptions = (data: BiOptions) => {
  biOptions = data;
};

const getLoadingTime = () => {
  if (typeof window !== 'undefined') {
    const endLoadTime = window.performance?.now();
    const startLoadTime = window.appStartLoadTime;

    if (endLoadTime && startLoadTime) {
      return endLoadTime - startLoadTime;
    }
  }

  return 0;
};

export const reportLoadSuccess = () => {
  bi.log({
    openingSiteID: biOptions.siteId,
    evid: 43,
    siteType: '2',
    originappurl: biOptions.originUrl,
    loadingTime: Math.round(getLoadingTime()),
  });
};

export const reportMobileView = () => {
  bi.log({
    evid: 27,
    siteType: '2',
    openingSiteId: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportDesktopView = () => {
  bi.log({
    evid: 28,
    siteType: '2',
    openingSiteID: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportTabletView = () => {
  bi.log({
    evid: 98,
    siteType: '2',
    openingSiteID: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportEditorClick = (esi: string | undefined, origin: string) => {
  bi.log({
    openingSiteId: biOptions.siteId,
    originappurl: biOptions.originUrl,
    siteType: '2',
    esi,
    galleryDocIndex: biOptions.galleryDocIndex,
    origin,
    book: biOptions.bookName,
    evid: 15,
    template_type: biOptions.isAdi ? 'ADI' : 'Editor',
  });
};

export const reportInfoClick = () => {
  bi.log({
    evid: 19,
    siteType: '2',
    openingSiteId: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportResponsiveTemplateLoaded = () => {
  biEditorX.log({
    evid: 700,
    pageName: 'template_viewer',
    pageUrl: window.location.origin + window.location.pathname,
    origin: biOptions.originUrl || biOptions.referer || 'direct_link',
    templateID: biOptions.siteId || null,
  });
};

export const reportActionButtonClick = (action: any, text: any) => {
  biEditorX.log({
    evid: 707,
    pageName: 'template_viewer',
    pageUrl: window.location.origin + window.location.pathname,
    origin: 'top_bar',
    templateID: biOptions.siteId || null,
    action,
    text,
  });
};

export const reportTemplateEditButtonClick = () => {
  let origin = '';
  const referrer = biOptions.referer || '';
  const parsedUrl = URL.parse(referrer, true);
  const isEditorXHost = parsedUrl.hostname === 'www.editorx.com';
  const isStartPoint =
    isEditorXHost && ['/create/start-point/', '/create/start-point'].includes(parsedUrl.pathname || '');
  const isExpressions = isEditorXHost && ['/templates/', '/templates'].includes(parsedUrl.pathname || '');

  if (isStartPoint) {
    origin = 'start point';
  } else if (isExpressions) {
    origin = 'expressions';
  }

  biEditorX.log({
    evid: 705,
    pageName: 'template_viewer',
    pageUrl: window.location.origin + window.location.pathname,
    origin: 'top_bar',
    template_id: biOptions.siteId || null,
  });

  biGeneric.log({
    evid: 804,
    screen_name: 'viewer',
    action: 'edit',
    origin,
    template_id: biOptions.siteId || null,
    screen_width: screen.width,
    screen_height: screen.height,
  });
};

export const reportHomeClick = () => reportActionButtonClick('home', '');
