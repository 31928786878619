import { combineReducers, Reducer } from 'redux';
import { viewMode } from './viewMode';
import { template } from './template';
import { isInfoShown } from './isInfoShown';
import { StoreConfig, StoreState } from '../configureStore';

export const rootReducer: Reducer<StoreState> = combineReducers({
  viewMode,
  template,
  isInfoShown,
  experiments: (state: string[] = []) => state,
  originUrl: (state = '') => state,
  config: (state: Partial<StoreConfig> = {}) => state,
  isEditButtonHidden: (state = false) => state,
});
