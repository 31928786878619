import React, { Component } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import cs from 'classnames';
import s from './mobileApp.scss';
import * as BIManager from '../../utils/bi';
import { MobileToolbar } from '../partials/MobileToolbar/MobileToolbar';
import InfoPopUp from '../../containers/InfoPopUp/InfoPopUp';
import InfoButton from '../../containers/InfoButton/InfoButton';
import { MobileEditorXToolbar } from '../partials/MobileEditorXToolbar/MobileEditorXToolbar';
import { fedopsLogger } from '../../utils/fedops-logger';
import { StoreTemplate } from '../../store/reducers/template';

export interface MobileAppProps extends WithTranslation {
  isInfoShown: boolean;
  template: StoreTemplate;
  isEditButtonHidden: boolean;
}

export class MobileAppCmp extends Component<MobileAppProps> {
  componentDidMount() {
    BIManager.reportLoadSuccess();
    if (this.props.template.isResponsive) {
      BIManager.reportResponsiveTemplateLoaded();
    }
    fedopsLogger.appLoaded();
  }

  render() {
    const { isInfoShown, template, isEditButtonHidden, t } = this.props;
    const { url, isResponsive, title } = template;

    const previewUrl = `${url}?showMobileView=true`;

    return (
      <div data-hooks="app">
        {isResponsive ? (
          [
            <MobileEditorXToolbar template={template} key="toolbar" isEditButtonHidden={isEditButtonHidden} />,
            <div className={s.goToBiggerScreen} key="bigger-screen" data-hooks="bigger-screen">
              {t('template.viewer.goToBiggerScreen')}
            </div>,
          ]
        ) : (
          <MobileToolbar>
            <InfoButton />
          </MobileToolbar>
        )}
        <iframe
          data-hooks="mobile-iframe"
          title={title}
          src={previewUrl}
          width="100%"
          height="100%"
          className={cs(s.iframe, { [s.iframeHidden]: isInfoShown })}
        />
        {/* @ts-expect-error ts-migrate(17004) FIXME: Cannot use JSX unless the '--jsx' flag is provided... Remove this comment to see the full error message */}
        <InfoPopUp isMobile />
        {isResponsive && (
          <div className={s.seeAllExpressions} data-hooks="see-all-expressions">
            <a href="https://www.wix.com/editorx/templates" className={s.seeAllExpressionsLink}>
              <span className={s.seeAllExpressionsLinkText}>{t('template.viewer.seeAllExpressions')}</span>
              &nbsp;→
            </a>
          </div>
        )}
      </div>
    );
  }
}

export const MobileApp = withTranslation()(MobileAppCmp);
