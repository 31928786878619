import { connect } from 'react-redux';
import { TemplateDemo } from '../../components/TemplateDemo/TemplateDemo';
import { StoreState } from '../../store/configureStore';

function mapStateToProps(state: StoreState) {
  return {
    url: state.template.url,
    title: state.template.title,
    viewMode: state.viewMode,
    isInfoShown: state.isInfoShown,
  };
}

export default connect(mapStateToProps)(TemplateDemo);
