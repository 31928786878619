import { connect } from 'react-redux';
import { MobileApp } from '../../components/MobileApp/MobileApp';
import { StoreState } from '../../store/configureStore';

export const mapStateToProps = (state: StoreState) => ({
  isInfoShown: state.isInfoShown,
  template: state.template,
  isEditButtonHidden: state.isEditButtonHidden,
});

export default connect(mapStateToProps)(MobileApp);
