import React from 'react';
import cs from 'classnames';
import s from './templateDemo.scss';
import { VIEW_MODE } from '../../utils/helpers';
import { StoreTemplate } from '../../store/reducers/template';

interface Props {
  template: StoreTemplate;
  viewMode: string;
}

export function EditorXTemplateDemo({ template, viewMode }: Props) {
  const isMobile = viewMode === VIEW_MODE.MOBILE;

  let previewUrl = template.url;
  if (isMobile) {
    previewUrl += '?showMobileView=true';
  }

  return (
    <div data-hooks="editorx-template-demo" className={s.editorXTemplateDemo}>
      <iframe
        title={template.title}
        data-hooks="editorx-template-demo-iframe"
        src={previewUrl}
        className={cs(s.iframe, {
          [s.tablet]: viewMode === VIEW_MODE.TABLET,
          [s.mobile]: viewMode === VIEW_MODE.MOBILE,
        })}
      />
    </div>
  );
}
