import React from 'react';
import { WixLogo } from '../WixLogo/WixLogo';
import s from './MobileToolbar.scss';

interface Props {
  children?: React.ReactNode;
  onLogoClick?: (...args: any[]) => any;
}

export const MobileToolbar = ({ children, onLogoClick }: Props) => (
  <div data-hooks="mobile-tool-bar" className={s.mobileToolBar}>
    <WixLogo onClick={onLogoClick} />
    {children}
  </div>
);
