import { AnyAction } from 'redux';
import { connect, Dispatch } from 'react-redux';
import { ToolBar } from '../../components/ToolBar/ToolBar';
import { toggleViewMode, toggleInfoMode } from '../../store/actions';
import * as BIManager from '../../utils/bi';
import { VIEW_MODE } from '../../utils/helpers';
import { StoreState } from '../../store/configureStore';

function mapStateToProps(state: StoreState) {
  return {
    viewMode: state.viewMode,
    template: state.template,
    editorClick: (esi: string | undefined, origin: string) => BIManager.reportEditorClick(esi, origin),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    toggleViewMode: (viewMode: VIEW_MODE) => {
      if (viewMode === VIEW_MODE.DESKTOP) {
        BIManager.reportDesktopView();
      } else {
        BIManager.reportMobileView();
      }

      return dispatch(toggleViewMode(viewMode));
    },
    toggleInfoMode: (show: boolean) => {
      show && BIManager.reportInfoClick();
      return dispatch(toggleInfoMode(show));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);
