import React from 'react';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import * as BIManager from '../../../utils/bi';
import { WhiteToolBarButton } from '../WhiteToolBarButton/WhiteToolBarButton';
import s from './EditorXToolBar.scss';
import { Switcher } from './Switcher/Switcher';
import { EditorXLogo } from '../EditorXLogo/EditorXLogo';
import { StoreTemplate } from '../../../store/reducers/template';
import { VIEW_MODE } from '../../../utils/helpers';

interface Props extends WithTranslation {
  template: StoreTemplate;
  viewMode: VIEW_MODE;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
}

const EditorXToolBarCmp = ({ t, template, viewMode, toggleViewMode }: Props) => (
  <div className={s.editorXToolBar} data-hooks="editorx-toolbar-with-switcher">
    <div className={s.logo}>
      <EditorXLogo isLarge />
    </div>
    <div className={s.switcher}>
      <Switcher viewMode={viewMode} toggleViewMode={toggleViewMode} />
    </div>
    <div className={s.button}>
      <WhiteToolBarButton href={template.editorUrl} onClick={() => BIManager.reportTemplateEditButtonClick()}>
        {t('template.viewer.editTemplate')}
      </WhiteToolBarButton>
    </div>
  </div>
);

export const EditorXToolBar = withTranslation()(EditorXToolBarCmp);
