import { AnyAction } from 'redux';
import { TOGGLE_INFO_MODE } from '../actions';

export function isInfoShown(state = false, action: AnyAction) {
  switch (action.type) {
    case TOGGLE_INFO_MODE:
      return action.show;
    default:
      return state;
  }
}
