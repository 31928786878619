import { AnyAction } from 'redux';
import { connect, Dispatch } from 'react-redux';
import { DesktopApp } from '../../components/DesktopApp/DesktopApp';
import { VIEW_MODE } from '../../utils/helpers';
import * as BIManager from '../../utils/bi';
import { toggleViewMode } from '../../store/actions';
import { StoreState } from '../../store/configureStore';

function mapStateToProps(state: StoreState) {
  return {
    viewMode: state.viewMode,
    template: state.template,
    isInfoShown: state.isInfoShown,
    lang: state.config.lang,
    dealerCmsTranslationsUrl: state.config.dealerCmsTranslationsUrl,
    dealerLightboxUrl: state.config.dealerLightboxUrl,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    toggleViewMode: (viewMode: VIEW_MODE) => {
      if (viewMode === VIEW_MODE.DESKTOP) {
        BIManager.reportDesktopView();
      }
      if (viewMode === VIEW_MODE.TABLET) {
        BIManager.reportTabletView();
      }
      if (viewMode === VIEW_MODE.MOBILE) {
        BIManager.reportMobileView();
      }

      return dispatch(toggleViewMode(viewMode));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopApp);
