import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { i18n } from './i18n/client-i18n';
import { App } from './App/App';
import { configureStore } from './store/configureStore';
import * as BIManager from './utils/bi';
import { DEVICE } from './utils/helpers';
import { initFullstory } from './utils/fullstory';
import supportChatWidget from '@wix/support-chat-widget-wrapper';
import { initCookieAndTagManagers } from './utils/initCookieAndTagManagers';

const initialI18n = window.__INITIAL_I18N__;
const baseURL = window.__BASEURL__;
const initialState = window.__INITIAL_STATE__;
const consentPolicy = window.__CONSENT_POLICY__;

initCookieAndTagManagers({
  baseUrl: baseURL,
  consentPolicy,
  language: initialI18n.locale,
});

initFullstory();
const store = configureStore(initialState);
const device = window.__DEVICE__;
BIManager.setBIOptions(window.__BI__);

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n(initialI18n)}>
      <App mobile={device !== DEVICE.DESKTOP} />
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

if (supportChatWidget) {
  supportChatWidget.init({ origin: 'TemplatesViewer' });
}
