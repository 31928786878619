import React from 'react';
import s from './Switcher.scss';
import { VIEW_MODE } from '../../../../utils/helpers';
import { SwitcherButton } from './SwitcherButton/SwitcherButton';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

interface Props extends WithTranslation {
  viewMode: VIEW_MODE;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
}

export function SwitcherCmp({ viewMode, toggleViewMode, t }: Props) {
  return (
    <div className={s.switcher}>
      <SwitcherButton
        viewMode={VIEW_MODE.DESKTOP}
        activeView={viewMode}
        tooltip={t('toolbar.tooltip.desktop')}
        toggleViewMode={toggleViewMode}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="72" viewBox="0 0 57 72">
          <path
            fillRule="evenodd"
            d="M34.333 45.333V48H23.667v-2.667h10.666zm4-21.333C39.804 24 41 25.196 41 26.667V40c0 1.47-1.196 2.667-2.667 2.667H19.667C18.196 42.667 17 41.47 17 40V26.667C17 25.196 18.196 24 19.667 24h18.666zm0 2.667H19.667V40h18.668l-.002-13.333z"
          />
        </svg>
      </SwitcherButton>
      <SwitcherButton
        viewMode={VIEW_MODE.TABLET}
        activeView={viewMode}
        tooltip={t('toolbar.tooltip.tablet')}
        toggleViewMode={toggleViewMode}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="72" viewBox="0 0 57 72">
          <path
            fillRule="evenodd"
            d="M35.286 26.665l-13.572.002v18.666h13.572V26.665zm0 21.335H21.714C20.217 48 19 46.804 19 45.333V26.667C19 25.196 20.217 24 21.714 24h13.572C36.783 24 38 25.196 38 26.667v18.666C38 46.804 36.783 48 35.286 48z"
          />
        </svg>
      </SwitcherButton>
      <SwitcherButton
        viewMode={VIEW_MODE.MOBILE}
        activeView={viewMode}
        tooltip={t('toolbar.tooltip.mobile')}
        toggleViewMode={toggleViewMode}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="72" viewBox="0 0 57 72">
          <path
            fillRule="evenodd"
            d="M32.4 27.624l-7.8.001v15.75h7.8V27.624zM32.4 46h-7.8c-1.434 0-2.6-1.177-2.6-2.625v-15.75C22 26.177 23.166 25 24.6 25h7.8c1.434 0 2.6 1.177 2.6 2.625v15.75C35 44.823 33.834 46 32.4 46z"
          />
        </svg>
      </SwitcherButton>
    </div>
  );
}

export const Switcher = withTranslation()(SwitcherCmp);
