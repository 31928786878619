import URL from 'url';

function getUrlParameters(url: string): string[] {
  return url.substring(url.indexOf('?') + 1, url.length).split('&');
}

function getParameterValue(parameters: string[], id: string): string | undefined {
  let value: string | undefined;
  parameters.forEach((singleParameter: string) => {
    const query = singleParameter.split('=');
    if (query[0] === id) {
      value = query[1];
    }
  });

  return value;
}

function extractEditorUrlFromAppsInstallerUrl(url: string): string {
  if (url.indexOf('https://www.wix.com/website/features') === 0) {
    const parsed = URL.parse(url, true);
    // @ts-expect-error ts-migrate(2345) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    return decodeURIComponent(parsed.query.redirectUrl);
  }
  return url;
}

export const getEsi = (url: string): string | undefined => {
  const editorUrl = extractEditorUrlFromAppsInstallerUrl(url);
  return getParameterValue(getUrlParameters(editorUrl), 'editorSessionId');
};

export const getMetaSiteId = (url: any): string | undefined => {
  const editorUrl = extractEditorUrlFromAppsInstallerUrl(url);
  return getParameterValue(getUrlParameters(editorUrl), 'metaSiteId');
};
