import { initI18nWithoutICU, Messages } from '@wix/wix-i18n-config';

export interface InitialI18n {
  locale: string;
  resources: Messages;
}

export function i18n({ locale, resources }: InitialI18n) {
  return initI18nWithoutICU({
    locale,
    messages: resources,
  });
}
