import { connect } from 'react-redux';
import { InfoButton } from '../../components/InfoButton/InfoButton';
import { toggleInfoMode } from '../../store/actions';
import * as BIManager from '../../utils/bi';
import { StoreState } from '../../store/configureStore';

function mapStateToProps(state: StoreState) {
  return {
    isInfoShown: state.isInfoShown,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    toggleInfoMode: (show: boolean) => {
      if (show) {
        BIManager.reportInfoClick();
      }
      return dispatch(toggleInfoMode(show));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InfoButton);
