import { ConsentPolicy } from '@wix/cookie-consent-policy-client';

export function initCookieAndTagManagers({
  baseUrl,
  consentPolicy,
  language,
}: {
  baseUrl: string;
  consentPolicy?: ConsentPolicy;
  language: string;
}) {
  if (window?.consentPolicyManager?.init) {
    window.consentPolicyManager.init({
      baseUrl,
      consentPolicy,
    });
  }
  if (window?.wixTagManager?.init) {
    window.wixTagManager.init('TEMPLATES', language);
  }
}
