import React from 'react';
import cs from 'classnames';
import s from './EditorXLogo.scss';
import * as BIManager from '../../../utils/bi';
import { ReactComponent as Logo } from '@wix/brand-resources/resources/EditorX/EditorX_logo_14x60_black.svg';

interface Props {
  className?: string;
  isLarge: boolean;
}

export const EditorXLogo = ({ className, isLarge }: Props) => (
  <a
    data-hooks="logo"
    href="https://www.wix.com/editorx"
    onClick={BIManager.reportHomeClick}
    className={cs(s.logo, className)}
  >
    <Logo className={cs(s.image, { [s.large]: isLarge })} fill="#000" />
    <span className={s.hide}>EditorX</span>
  </a>
);
