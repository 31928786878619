import React, { Component } from 'react';
import './DesktopApp.scss';
import ToolBar from '../../containers/ToolBar/ToolBar';
import TemplateDemo from '../../containers/TemplateDemo/TemplateDemo';
import InfoPopUp from '../../containers/InfoPopUp/InfoPopUp';
import * as BIManager from '../../utils/bi';
import { fedopsLogger } from '../../utils/fedops-logger';
import { initDealer } from '../../utils/initDealer';
import { EditorXToolBar } from '../partials/EditorXToolBar/EditorXToolBar';
import { EditorXTemplateDemo } from '../EditorXTemplateDemo/EditorXTemplateDemo';
import { StoreTemplate } from '../../store/reducers/template';
import { VIEW_MODE } from '../../utils/helpers';

interface Props {
  className?: string;
  template: StoreTemplate;
  viewMode: VIEW_MODE;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
  isInfoShown: boolean;
  lang: string | undefined;
  dealerCmsTranslationsUrl: string;
  dealerLightboxUrl: string;
}

export class DesktopApp extends Component<Props> {
  componentDidMount() {
    BIManager.reportLoadSuccess();
    if (this.props.template.isResponsive) {
      BIManager.reportResponsiveTemplateLoaded();
    }
    fedopsLogger.appLoaded();

    const { lang, dealerCmsTranslationsUrl, dealerLightboxUrl } = this.props;
    const { editorSessionId, siteId, metaSiteId } = this.props.template;

    initDealer({
      editorSessionId,
      metaSiteId,
      siteId,
      lang,
      dealerCmsTranslationsUrl,
      dealerLightboxUrl,
    });
  }

  componentDidUpdate() {
    document.getElementsByTagName('body')[0].classList.toggle('popup', this.props.isInfoShown);
  }

  render() {
    const { template, viewMode, toggleViewMode } = this.props;

    return (
      <div data-hooks="app">
        {template.isResponsive ? (
          <>
            <EditorXToolBar template={template} viewMode={viewMode} toggleViewMode={toggleViewMode} />
            <EditorXTemplateDemo template={template} viewMode={viewMode} />
          </>
        ) : (
          <>
            <ToolBar />
            <TemplateDemo />
          </>
        )}
        <InfoPopUp />
      </div>
    );
  }
}
