import { connect, Dispatch } from 'react-redux';
import { InfoPopUp } from '../../components/InfoPopUp/InfoPopUp';
import { toggleInfoMode } from '../../store/actions';
import * as BIManager from '../../utils/bi';
import { StoreState } from '../../store/configureStore';
import { AnyAction } from 'redux';

function mapStateToProps(state: StoreState) {
  return {
    template: state.template,
    isInfoShown: state.isInfoShown,
    editorClick: (esi: string | undefined, origin: string) => BIManager.reportEditorClick(esi, origin),
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>) {
  return {
    toggleInfoMode: (show: boolean) => dispatch(toggleInfoMode(show)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoPopUp);
