export enum VIEW_MODE {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export enum DEVICE {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
