import { createStore } from 'redux';
import { rootReducer } from './reducers';
import { VIEW_MODE } from '../utils/helpers';
import { StoreTemplate } from './reducers/template';

export function configureStore(initialState: Partial<StoreState>) {
  // @ts-expect-error //FIXME: export some default state.
  return createStore<StoreState>(rootReducer, initialState);
}

export interface StoreState {
  viewMode: VIEW_MODE;
  isInfoShown: boolean;
  template: StoreTemplate;
  experiments: string[];
  originUrl?: string;
  config: StoreConfig;
  isEditButtonHidden: boolean;
}

export interface StoreConfig {
  locale: string;
  lang?: string;
  dealerCmsTranslationsUrl: string;
  dealerLightboxUrl: string;
}
