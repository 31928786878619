import React from 'react';
import s from './mobileView.scss';

interface Props {
  url: string;
  title: string;
}

export function MobileView({ url, title }: Props) {
  const previewUrl = `${url}?showMobileView=true`;

  return (
    <div data-hooks="mobile-view" className={`${s.display}`}>
      <div className={s.phone}>
        <div className={s.speaker} />
        <div className={`${s.display} ${s.device}`}>
          <iframe data-hooks="mobile-iframe" src={previewUrl} title={title} width="100%" height="100%" />
        </div>
      </div>
    </div>
  );
}
