import React from 'react';
import s from './WhiteToolBarButton.scss';

interface Props {
  href?: string;
  onClick?: (...args: any[]) => any;
  children?: React.ReactNode;
}

export const WhiteToolBarButton = ({ href, onClick, children }: Props) => (
  <a href={href} onClick={onClick} className={s.button}>
    {children}
  </a>
);
