import React, { Component } from 'react';
import s from './desktopView.scss';

interface Props {
  url: string;
  title: string;
}

export class DesktopView extends Component<Props> {
  props: any;

  render() {
    const { url, title } = this.props;
    return (
      <div data-hooks="desktop-view" className={s.display}>
        <iframe data-hooks="desktop-iframe" src={url} title={title} width="100%" height="100%" className={s.iframe} />
      </div>
    );
  }
}
