import React, { Component } from 'react';
import { VIEW_MODE } from '../../utils/helpers';
import { getEsi } from '../../utils';
import s from './ToolBar.scss';
import { WixLogo } from '../partials/WixLogo/WixLogo';
import InfoButton from '../../containers/InfoButton/InfoButton';
import { StoreTemplate } from '../../store/reducers/template';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

interface Props extends WithTranslation {
  viewMode: VIEW_MODE;
  template: StoreTemplate;
  toggleViewMode: (viewMode: VIEW_MODE) => any;
  toggleInfoMode: (infoMode: boolean) => any;
  editorClick: (esi: string | undefined, origin: string) => any;
}

class ToolBarCmp extends Component<Props> {
  componentDidMount() {
    window.templateEditUrl = this.props.template.editorUrl; // Adoric need to have this link in the window
  }

  render() {
    const { t, viewMode, template, toggleViewMode, editorClick } = this.props;
    return (
      <div data-hooks="tool-bar" className={s.toolBar}>
        <div className={s.elementsGroup}>
          <WixLogo />
          <div className={s.elementsGroup}>
            <button
              data-hooks="desktop-view"
              className={`${s.desktopIcon} ${viewMode === VIEW_MODE.DESKTOP ? s.selected : ''}`}
              onClick={() => (viewMode === VIEW_MODE.DESKTOP ? '' : toggleViewMode(VIEW_MODE.DESKTOP))}
            >
              <span className={s.hide}>{t('a11y.desktop.button')}</span>
            </button>
            <hr className={s.hr} />
            <button
              data-hooks="mobile-view"
              className={`${s.mobileIcon} ${viewMode === VIEW_MODE.MOBILE ? s.selected : ''}`}
              onClick={() => (viewMode === VIEW_MODE.MOBILE ? '' : toggleViewMode(VIEW_MODE.MOBILE))}
            >
              <span className={s.hide}>{t('a11y.mobile.button')}</span>
            </button>
          </div>
        </div>
        <div className={`${s.elementsGroup} ${s.actionMenu}`}>
          <p data-hooks="tool-bar-title" className={s.title}>
            {t('template.viewer.title')}
          </p>
          <InfoButton />
          <a
            className={s.button}
            data-hooks="editor-link"
            href={template.editorUrl}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            tabIndex={0}
            onClick={() => editorClick(getEsi(template.editorUrl), 'viewer_top')}
          >
            {t('template.viewer.edit.button')}
          </a>
        </div>
      </div>
    );
  }
}

export const ToolBar = withTranslation()(ToolBarCmp);
