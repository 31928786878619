import React from 'react';
import MobileApp from '../containers/MobileApp/MobileApp';
import DesktopApp from '../containers/DesktopApp/DesktopApp';
import './App.scss';

interface Props {
  mobile: boolean;
}

export const App = ({ mobile }: Props) => (mobile ? <MobileApp /> : <DesktopApp />);
